import React from "react";
import { Link } from "react-router-dom";
import Sixtynine from "./Sixtynine";
import Footer from "./Footer";

export default function Home() {
  return (
    <div className="h-screen">
      <div className="nav p-10">
        <h1 className="text-5xl md:text-7xl font-mont font-bold">
          SPOT <span className="text-[#cae962]">360</span>
        </h1>
      </div>
      <div className="md:h-3/4 flex gap-16 flex-col items-center justify-center">
        <h1 className="text-center md:text-4xl"><span className="md:text-5xl text-lg text-[#cae962]">360°</span> views for <span className="md:text-5xl text-lg text-[#cae962]">360°</span> satisfaction in your own way</h1>
        <p className="md:text-2xl text-lg w-3/4 md:w-1/2  text-justify">
          Spot360 is a leading provider of innovative AR/VR solutions, with a
          strong focus on revolutionizing the construction industry. Our
          expertise extends across a range of basic projects, ensuring seamless
          and efficient processes. We take pride in our commitment to
          excellence, particularly in the realm of house building. Additionally,
          we bring our unparalleled expertise to diverse sectors, including
          hotel rental/sale, home building, and gym infrastructure. Our projects
          are characterized by a harmonious blend of sophistication and
          simplicity. At Spot360, we understand the importance of trust, and we
          invite you to explore our website for more details and to reach out
          for any further inquiries. Join us in transforming visions into
          reality.
        </p>
        <p className="btn w-28 bg-[#cae962] hover:bg-[#cae962]/25  text-black">
          <Link to="/preview">Check</Link>
        </p>
      </div>
      {/* <p>/upload for upload page</p>
      <h1>karthik maharaja please style this</h1>
      <p>still some changes need to be done</p>
      <p>
        getting the id of the upoladed video but need to store that to display.
        nedd to find a way for that
      </p>
      <p>other than that everything is finished</p> */}
      <Sixtynine />
      <Footer />
    </div>
  );
}
