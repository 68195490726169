import React from 'react'

export default function Sixtynine() {
 return (
    <>
        <div className='flex mb-0 items-center p-10 justify-center gap-5'>
          <h1 className='text-xl md:text-4xl'>Developed by</h1>
            <a href="https://69thstop.netlify.app/" target='_blank' className='w-20'><img src="https://69thstop.netlify.app/Logo.png" alt="" /></a>
        </div>
    </>
  )
}
