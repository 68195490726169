import React, { useRef, useState } from "react";
import Upload from "./Upload";
import { Link } from "react-router-dom";
import Sixtynine from "./Sixtynine";
import Footer from "./Footer";

export default function Access() {
  const [value, setValue] = useState("hidden");
  const myRef = useRef();
  function check() {
    console.log(myRef.current.value);
    if (myRef.current.value === "spot360") {
      setValue(null);
    }
  }
  return (
    <div className="h-screen">
      <h1 className="text-7xl p-10">
        Acc<span className="text-[#cae962]">ess</span>
      </h1>
      <div className="h-3/4 flex flex-col items-center justify-center">
        <div className="w-1/2 flex gap-5 flex-col md:flex-row items-center justify-between">
          <h1 className="md:text-4xl text-xl">Enter the Access pin</h1>
          <input type="text" className="h-12 md:w-1/2" ref={myRef} />
          <div className="btn bg-[#cae962] hover:bg-[#cae962]/50 w-24 text-black font-bold">
            <button onClick={check}>GO</button>
          </div>
        </div>
        <div className="flex items-center justify-center gap-10 p-10" style={{ visibility: value }}>
          <h1 className="md:text-4xl text-xl">Upload the Video</h1>
          <div className="btn bg-[#cae962] hover:bg-[#cae962]/50 text-black">
            <Link to="/access">Upload</Link>
          </div>
        </div>
      </div>
      <Sixtynine />
      <Footer />
    </div>
  );
}
