import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";

import "../App.css";

export default function Footer() {
  return (
    <div className="footer-bg w-full rounded">
      <div className="md:p-10 p-5">
        <h1 className="md:text-4xl text-2xl font-bold text-[#cae962]">Contact Us</h1>
        <div className="flex flex-col md:flex-row items-center p-10 justify-center gap-28">
          <div className="flex flex-col gap-5 items-start justify-center">
            <h1 className="text-2xl font-bold text-white">
              B.B.Naveen krishna
            </h1>
            <a
              className="text-xl flex items-center justify-center gap-4 text-white hover:text-[#cae962] hover:underline underline-offset-4"
              href="tel:+916374846179"
            >
              <BsFillTelephoneFill />
              6374846179
            </a>
            <a
              className="text-xl flex items-center justify-center gap-4 text-white hover:text-[#cae962] hover:underline underline-offset-4"
              href="mailto:@naveenkrishna2323@gmail.com"
            >
              <HiMail />
              naveenkrishna2323@gmail.com
            </a>
          </div>
          <div className="flex flex-col gap-5 items-start justify-center">
            <h1 className="text-2xl font-bold text-white">Madhav V Tejas</h1>
            <a
              className="text-xl flex items-center justify-center gap-4 text-white hover:text-[#cae962] hover:underline underline-offset-4"
              href="tel:+916374564120"
            >
              <BsFillTelephoneFill />
              6374564120
            </a>
            <a
              className="text-xl flex items-center justify-center gap-4 text-white hover:text-[#cae962] hover:underline underline-offset-4"
              href="mailto:@madhavtejas605@gmail.com"
            >
              <HiMail />
              madhavtejas605@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
