import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Sixtynine from "./Sixtynine";

export default function Explore() {
  return (
    <div>
      <div className="flex flex-col items-center justify-center p-10">
        <h1 className="text-5xl text-[#cae962] p-10 ">
          View more About the Site
        </h1>
        <p className="md:p-32 text-xl  text-justify">
          Land near Tambaram railway station DTCP approved 80% loan 
          <br></br>Contact us
          for more details:
          <a className="hover:text-[#cae962] hover:underline px-5" href="tel:+916374846179">6374846179</a>
        </p>
        <Link to="/">
          <div className="btn text-black hover:bg-[#cae962]/50 hover:text-white hover:shadow-xl bg-[#cae962] w-28 h-10">
            <a>Home</a>
          </div>
        </Link>
      </div>
      <Sixtynine />
      <Footer />
    </div>
  );
}
