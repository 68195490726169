import { useEffect, useRef } from "react";
import Sixtynine from "./Sixtynine";

const VideoPlayer = ({ id, publicId, ...props }) => {
  const videoRef = useRef();
  const cloudinaryRef = useRef();
  const playerRef = useRef();

  // Store the Cloudinary window instance to a ref when the page renders

  useEffect(() => {
    if (cloudinaryRef.current) return;

    cloudinaryRef.current = window.cloudinary;

    playerRef.current = cloudinaryRef.current.videoPlayer(videoRef.current, {
      cloud_name: process.env.REACT_APP_CLOUD,
      secure: true,
    });
  }, []);

  return (
    <div className="flex flex-col h- items-center justify-center w-full">
      <div
        className=" flex items-center justify-center"
        style={{
          width: "80%",
          aspectRatio: `${props.width} / ${props.height}`,
        }}
      >
        <video
          ref={videoRef}
          id={id}
          className="cld-video-player cld-fluid"
          controls
          autoPlay
          data-cld-public-id={publicId}
          {...props}
        />
        
      </div>
      
    </div>
  );
};

export default VideoPlayer;
