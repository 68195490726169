import logo from './logo.svg';
import './App.css';
import { Routes,Route } from 'react-router-dom';
import Home from './components/Home';
import Preview from './components/Preview';
import Upload from './components/Upload';
import Denied from './components/Denied';
import Access from './components/Access';
import Explore from './components/Explore';


function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/preview' element={<Preview />} />
      <Route path='/access' element={<Upload />} />
      <Route path='/upload' element={<Access />} />
      <Route path='/denied' element={<Denied /> } />
      <Route path='/explore' element={<Explore/>}></Route>
    </Routes>
    
  );
}

export default App;
