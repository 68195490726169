import React, { useEffect } from "react";
import VideoPlayer from "./VideoPlayer";
import Sixtynine from "./Sixtynine";
import Footer from "./Footer";
import { Link } from "react-router-dom";

export default function Preview() {
  return (
    <div>
      <h1 className="md:text-7xl text-4xl p-5">Pre<span className="text-[#cae962]">view</span>
        </h1>
      <div className="flex flex-col items-center justify-center gap-10">
        
         
        <VideoPlayer
          id="demo-player"
          publicId="b1kg42qrz6tedcaltxyb"
          width="3840"
          height="2160"
        />
        <Link to="/explore">
          <div className="btn text-black hover:bg-[#cae962]/50 hover:text-white hover:shadow-xl bg-[#cae962] w-28 h-10">
            <a>View More</a>
          </div>
        </Link>
        <Sixtynine />
        <Footer />
      </div>
    </div>
  );
}
