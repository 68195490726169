import React, { useEffect, useRef } from "react";
import Sixtynine from "./Sixtynine";
import Footer from "./Footer";

export default function Upload() {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD,
        uploadPreset: process.env.REACT_APP_PRESET,
      },
      function (error, result) {
        console.log(result.info);
        if (result.event === "success") {
          console.log(result.info.public_id);
        }
      }
    );
  }, []);

  return (
    <div className="h-screen">
      <h1 className="md:text-7xl text-4xl p-10">
        Up<span className="text-[#cae962]">load</span>
      </h1>
      <div className="h-3/4 flex items-center justify-center">
        <div className="flex gap-5 md:gap-10 items-center justify-center">
            <h1 className="text-2xl md:text-7xl">Upload the video</h1>
          <div className="btn bg-[#cae962] hover:bg-[#cae962]/50  w-24 text-black">
            <button onClick={() => widgetRef.current.open()}>Upload</button>
          </div>
        </div>
      </div>
      <Sixtynine />
      <Footer />
    </div>
  );
}
